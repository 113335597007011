<template>
    <!-- /// use Libs version if possible -->
    <div class="app-container h-100">
        <div id="chartContainer" class="p-2" style="width: 100%; height:100%"></div>
    </div>
</template>

<script setup lang="ts">
import { useDataObjectEventListener } from 'o365.vue.composables.EventListener.ts';
import { getOrCreateDataObject } from 'o365.vue.ts';
import { onMounted, ref, defineExpose } from 'vue';
import $t from "o365.modules.translate.ts";
import utils from "o365.modules.utils.js";
import { chartSeriesOrderCost, chartSeriesOrderHours, chartSeries, chartHoursSeries, chartPeriodicSeries, chartPeriodicHoursSeries, chartArchiveSeries, chartArchiveHrsSeries, chartBaselineSeries } from 'cost.vue.components.CostCurves.SeriesConfig.ts'
//import { dsTree } from 'cost.vue.Tree.WbsTreeConfig.ts'; cannot use new tree because of libs
import { chartControl } from 'cost.vue.components.CostCurves.Control.ts'

var chart = null; // main chart object

const props = defineProps({
    // useTreeNode: { //will result where clause populated with node data from imported tree dataobject, do not use with non libs tree
        // type: Boolean,
        // default: false
    // },
    zoomType: {
        type: String,
        default: 'x'
    },
    chartType: {
        type: String,
        default: 'costs' //hours
    },
    chartSettingsStorage: { //local storage name
        type: String,
        default: 'costCurveChartSettings'
    },
    seriesVisibilityStorage: { //local storage name
        type: String,
        default: 'costCurveChartSeriesVisibility'
    },
    hideHoursMenuItem: {
        type: Boolean,
        default: false
    },
    hideLegendToggle: { // will hide legend toggle and legend will be always shown
        type: Boolean,
        default: false
    },
    hideLegend: { // will hide legend toggle and all series will become visible
        type: Boolean,
        default: false
    },
    hidePeriodicToggle: { // will hide periodic toggle but not series, use hidePeriodic or excludeSeries
        type: Boolean,
        default: false
    },
    hidePeriodic: { // will hide periodic series and toggle
        type: Boolean,
        default: false
    },
    excludeSeries: { //['PlannedValue','EarnedValue']
        type: Array,
        default: null
    },
    useProjectSettings: { // for future functionality, where series exclusion could be defined in project settings
        type: Boolean,
        default: false
    },
});

const dsWBSCutOffsChart = getOrCreateDataObject({
    id: "dsWBSCutOffsChart_" + crypto.randomUUID(),
    viewName: "aviw_Cost_WBSCostCurveData_AllLevels",
    maxRecords: -1,
    uniqueTable: '',
    allowInsert: false,
    allowDelete: false,
    allowUpdate: false,
    loadRecents: false,
    distinctRows: false,
    definitionProc: "astp_Cost_WBSCostCurveData_AllLevelsDefinition",
    selectFirstRowOnLoad: true, // do not remove
    fields: [
        {name: "WBS_ID", sortOrder: 1, sortDirection: "asc", type: null},
        {name: "CutOff", sortOrder: 2, sortDirection: "asc", type: null},
        {name: "WBS", type: null},
        {name: "OrgUnitIdPath", type: null},
        {name: "OrgUnit_ID", type: null},
        {name: "IsCurrentCutOff", type: null},
        {name: "ForecastAtCompletion", type: null},
        {name: "BudgetAtCompletion", type: null},
        {name: "PlannedValue", type: null},
        {name: "ActualCost", type: null},
        {name: "EarnedValue", type: null},
        {name: "Booked", type: null},
        {name: "PaymentForecast", type: null},
        {name: "ForecastValueCurve", type: null},
        {name: "Archive1Total", type: null},
        {name: "Archive2Total", type: null},
        {name: "Archive1Value", type: null},
        {name: "Archive2Value", type: null},
        {name: "Archive1ValuePeriodic", type: null},
        {name: "Archive2ValuePeriodic", type: null},
        {name: "Archive1Progress", type: null},
        {name: "Archive2Progress", type: null},
        {name: "PlannedValuePeriodic", type: null},
        {name: "ForecastValuePeriodic", type: null},
        {name: "ActualCostPeriodic", type: null},
        {name: "EarnedValuePeriodic", type: null},
        {name: "BookedPeriodic", type: null},
        {name: "CommittedValuePeriodic", type: null},
        {name: "OriginalBaselineTotal", type: null},
        {name: "OriginalBaselineValue", type: null},
        {name: "OriginalBaselineValuePeriodic", type: null},
        {name: "PlannedHrs", type: null},
        {name: "ActualHrs", type: null},
        {name: "ForecastHrs", type: null},
        {name: "EarnedHrs", type: null},
        {name: "OriginalBaselineHrs", type: null},
        {name: "Archive1Hrs", type: null},
        {name: "Archive2Hrs", type: null},
        {name: "PlannedHrsPeriodic", type: null},
        {name: "ActualHrsPeriodic", type: null},
        {name: "ForecastHrsPeriodic", type: null},
        {name: "EarnedHrsPeriodic", type: null},
        {name: "OriginalBaselineHrsPeriodic", type: null},
        {name: "Archive1HrsPeriodic", type: null},
        {name: "Archive2HrsPeriodic", type: null},
        {name: "ForecastHrsTotal", type: null},
        {name: "PlannedHrsTotal", type: null},
        {name: "OriginalBaselineHrsTotal", type: null}
    ]
});

// chartControl.setContextFilter = (pFilter: string, pAutoLoad: boolean) => {
    // if(!pFilter) return;

    // dsWBSCutOffsChart.enableContextFilter( () => { return pFilter }, { autoLoad : pAutoLoad } );
// }

const dsProjectBaselineArchives = getOrCreateDataObject({
    id: 'dsProjectBaselineArchives_' + crypto.randomUUID(),
    viewName: 'aviw_Cost_ProjectBaselineArchives',
    maxRecords: 2,
    uniqueTable: '',
    allowInsert: false,
    allowDelete: false,
    allowUpdate: false,
    loadRecents: false,
    distinctRows: false,
    fields: [
        {name: "ArchiveID", type: "number", sortOrder:"1", direction:"asc"},
        {name: "Baseline_ID", type: "number" },
        {name: "BaselineName", type: "string" },
        {name: "Project_ID", type: "number" },
        {name: "BaselineType", type: "string" },   
        //{name: "ArchiveName", type: "string" }
    ]
});

//--------------------------------------------------------------------------------------------------//
//--------------------------------------------------------------------------------------------------//

//const createChart = () => {
const createOrRefreshChart = () => {
    setWhereClause();

    chart = Highcharts.chart('chartContainer', getChartSettings());
    chart.showLoading();

    dsWBSCutOffsChart.load(); //check DataLoaded Event
    //window.exposedChart = chart;
};

chartControl.createOrRefresh = createOrRefreshChart; //override import, do not delete

const setWhereClause = () => {
    let vWhereClause : Array<string> = [],
        vOrgUnit_ID : Number | null= null,
        vWBS_ID : Number | null = null,
        vFromPreviousCutOff : boolean = false,
        vStartDate: string | null = null,
        vFinishDate: string | null = null;

    //parameters

    //from import
    if (chartControl.definitionParameters.OrgUnit_ID || chartControl.definitionParameters.WBS_ID) {
        vOrgUnit_ID = chartControl.definitionParameters.OrgUnit_ID || null,
        vWBS_ID = chartControl.definitionParameters.WBS_ID || null
    }

    if (chartControl.definitionParameters.FromPreviousCutOff) {
        vFromPreviousCutOff = true;

        if (!chartControl.definitionParameters.AllPeriods && chartControl.definitionParameters.FinishDate) {
            vFinishDate = chartControl.definitionParameters.FinishDate;
        }
    } else if (!chartControl.definitionParameters.AllPeriods && chartControl.definitionParameters.StartDate && chartControl.definitionParameters.FinishDate) {
        vStartDate = chartControl.definitionParameters.StartDate;
        vFinishDate = chartControl.definitionParameters.FinishDate;
    }

    //from tree
    // if(props.useTreeNode) { // get node data from tree
        // if(dsTree?.current?.NodeType) {
            // if(dsTree.current.NodeType === 'WP' || dsTree.current.NodeType === 'WBS') {
                // vOrgUnit_ID = dsTree.current.OrgUnit_ID || null;
                // vWBS_ID = dsTree.current.ID || null;
            // } else {
                // vOrgUnit_ID = dsTree.current.ID || null;
                // vWBS_ID = null;
            // }
        // }
    // }

    dsWBSCutOffsChart.recordSource.sqlStatementParameters = {
        OrgUnit_ID: vOrgUnit_ID || null,
        WBS_ID: vWBS_ID || null,
        ImportID: null,
        FromPreviousCutOff: vFromPreviousCutOff,
        StartDate: vStartDate,
        FinishDate: vFinishDate
    }

    if (chartControl?.whereClause) {
        vWhereClause.push(chartControl.whereClause);
    }

    dsWBSCutOffsChart.recordSource.whereClause = vWhereClause.join(' AND ');
}

const getCurrentDataSource = () => {
    return dsWBSCutOffsChart;
}

const refreshChart = () => { //call this when chart exists
    let currentSeries = getCurrentSeries();

    let plotLines = [];
    let xCategories = dsWBSCutOffsChart.getData().map((row) => utils.formatDate(row.CutOff || row.Cutoff, "MM.yyyy"));
    let xSteps = scaleXAxis(xCategories.length);

    clearChart(chart, currentSeries);
    chart.xAxis[0].options.labels.step = xSteps;

    chart.xAxis[0].categories = xCategories;
    chart.options.exporting.chartOptions = {
        xAxis: {
            labels: {
                enabled: true,
                step: xSteps
            },
            categories: xCategories
        }
    }

    dsWBSCutOffsChart.getData().forEach(function (pRow, pIndex) {
        pRow._fields.forEach((i) => {
            if (currentSeries.hasOwnProperty(i.name)) {
                currentSeries[i.name].data.push([pRow[i.name]]);
            }
        })

        if (pRow.IsCurrentCutOff) {
            plotLines.push(chart.xAxis[0].addPlotLine({
                value: pIndex,
                color: 'grey',
                label: {
                    text: $t('Current Cutoff'),
                    align: 'centre'
                },
                width: 1,
                id: 'first',
                dashStyle: 'dash'
            }));
        }
    });
    for (var vSeriesKey in currentSeries) {
        if (currentSeries.hasOwnProperty(vSeriesKey)) {
            chart.addSeries(currentSeries[vSeriesKey], false);
        }
    }

    setChartTitle();

    chart.hideLoading();
    chart.redraw();
};

const setChartTitle = () => {
    chart.setTitle({ text: dsWBSCutOffsChart.current.WBS });
    chartConfig.title = dsWBSCutOffsChart.current.WBS;
};

const getMenuItems = () => {
    let menu = [
        "viewFullscreen",
        "printChart",
        "downloadPNG",
        "downloadJPEG",
        "downloadPDF"
    ];

    if( (!props.hidePeriodicToggle && !props.hidePeriodic) || (!props.hideLegendToggle && !props.hideLegend) ) {
        menu.push("separator");
    }
    if(!props.hideLegendToggle && !props.hideLegend) {
        menu.push("legend");
    }
    if(!props.hidePeriodicToggle && !props.hidePeriodic) {
        menu.push("periodic");
    }

    if (!props.hideHoursMenuItem) {
        menu.push("separator", "hours", "costs");
    }
    return menu;
};

const legendButtonDefinition = () => {
    return {
        text: getLegendInitialState() ? $t('Hide Legend') : $t('Show Legend'), //initial
        onclick: function () {
            let legend = chart.legend;
            const vSettings = getStorageObj(props.chartSettingsStorage);

            if (legend.display) {
                // Hide the legend
                chart.legend.update({ enabled: false });
                vSettings.showLegend = false;    
            } else {
                // Show the legend
                chart.legend.update({ enabled: true });
                vSettings.showLegend = true;
            }

            chart.exporting.update({ 
                menuItemDefinitions: { 
                    legend: { 
                        text: vSettings.showLegend ? $t('Hide Legend') : $t('Show Legend')
                    } 
                } 
            });

            setStorageObj(props.chartSettingsStorage, vSettings);
        }
    }
};

function getLegendInitialState() {
    if(props.hideLegend) {
        return false;
    }
    if(props.hideLegendToggle) {
        return true;
    }

    const vSettings = getStorageObj(props.chartSettingsStorage);
    if(vSettings && vSettings.hasOwnProperty('showLegend')){
        return vSettings.showLegend;
    } else {
        return true; //by default when no stored settings exist, legend is displayed
    }
}

const periodicButtonDefinition = () => {
    return { 
        text: getPeriodicInitialState() ? $t('Hide Periodic') : $t('Show Periodic'), //initial
        onclick: function () {
            const vSettings = getStorageObj(props.chartSettingsStorage);
            const vPeriodicAxisIndex = chart?.yAxis ? chart.yAxis.findIndex( axis => axis?.userOptions?.isPeriodic === true ) : null;

            if (vSettings?.showPeriodic) {
                // Hide the periodic values
                vSettings.showPeriodic = false;
                setStorageObj(props.chartSettingsStorage, vSettings);
            } else {
                // Show the periodic values
                vSettings.showPeriodic = true;
                setStorageObj(props.chartSettingsStorage, vSettings);
            }

            createOrRefreshChart();
            if(vPeriodicAxisIndex) {
                chart.yAxis[vPeriodicAxisIndex].update({visible: vSettings.showPeriodic}); //must go after redraw
            }

        }
    }
};

function getPeriodicInitialState() {
    if(props.hidePeriodic) {
        return false;
    }
    if(props.hidePeriodicToggle) {
        return true;
    }

    const vSettings = getStorageObj(props.chartSettingsStorage);
    if(vSettings && vSettings.hasOwnProperty('showPeriodic')){
        return vSettings.showPeriodic;
    } else {
        return false; //by default when no stored settings exist, periodic is not displayed
    }
}

function clearChart(pChart, currentSeries) {
    pChart.xAxis[0].removePlotLine("first");

    for (let i = pChart.series.length; i > 0; i--) {
        pChart.series[0].remove(false);
    }
    for (let property in currentSeries) {
        if (currentSeries.hasOwnProperty(property)) {
            currentSeries[property].data = [];
        }
    }
}

//--------------------------------------------------------------------------------------------------//
//-----------------------------------           EVENTS          ------------------------------------//
//--------------------------------------------------------------------------------------------------//

function onLegendItemClick(e) {
    const targetSeries = e.target;
    const uniqueName = targetSeries.userOptions.uniqueName;
    const vSeriesVisibility = getStorageObj(props.seriesVisibilityStorage);
    vSeriesVisibility[uniqueName] = !e.target.visible;
    setStorageObj(props.seriesVisibilityStorage, vSeriesVisibility);
}

dsProjectBaselineArchives.on("DataLoaded", () => {
    if (chart) {
        refreshChart();
    } else if (document.querySelector("#chartContainer")) {
        createOrRefreshChart();
    }
});


useDataObjectEventListener(dsWBSCutOffsChart, 'DataLoaded', () => {
    let projectId = dsWBSCutOffsChart.current.OrgUnit_ID || '-1';
    dsProjectBaselineArchives.recordSource.whereClause = "Project_ID = " + projectId;
    dsProjectBaselineArchives.load();

});

// onMounted(() => {
    // if (document.querySelector("#chartContainer")) {
        // createOrRefreshChart();
    // }
// });

//--------------------------------------------------------------------------------------------------//
//-----------------------------------     SERIES FUNCTIONS      ----------------------------------- //
//--------------------------------------------------------------------------------------------------//

const getCurrentSeries = () => {
    const vSeries = {
        ...getSeries(), 
        ...getPeriodicSeries(), 
        ...getArchiveSeries(),
        ...getBaselineSeries(),
    };

    //exclude series
    if(props.useProjectSettings) {
        //get project settings
        //const vTestArray = ['PlannedValue','Booked'];

        // for(var item in vSeries) { 
            // if(vTestArray.indexOf(item) > -1) {
                // delete vSeries[item];
            // }
        // }
        
    } else if(props.excludeSeries && props.excludeSeries.length > 0) {
        for(var item in vSeries) { 
            if(props.excludeSeries.indexOf(item) > -1) {
                delete vSeries[item];
            }
        }
    }
    

    // attaching uniqueName depending on key. is needed for saving visibility in storage
    for(var item in vSeries) { 
        if(!vSeries[item].uniqueName) {
            vSeries[item].uniqueName = item;
        }
    }

    if(props.hideLegend) {
        for(var item in vSeries) {
            vSeries[item].visible = true;
        }
    } else {
        //get series visibility from storage
        const vSeriesVisibilitySettings = getStorageObj(props.seriesVisibilityStorage);

        if(vSeriesVisibilitySettings) {
            for(var item in vSeries) {
                let uniqueName = vSeries[item].uniqueName;
                if(vSeriesVisibilitySettings.hasOwnProperty(uniqueName)){
                    vSeries[item].visible = vSeriesVisibilitySettings[uniqueName];
                }
            }
        }
    }

    const vSeriesSorted = sortSeries(vSeries);

    return vSeriesSorted;
}

const sortSeries = (pSeries : Object) : Object => {
    let vSeries = {};
    const vSortOrder = chartConfig.chartType === 'hours' ? chartSeriesOrderHours : chartSeriesOrderCost;

    vSortOrder.forEach((orderItem, index) => {
        if(pSeries.hasOwnProperty(orderItem)) {
            vSeries[orderItem] = pSeries[orderItem];
        }
    });

    return vSeries;
}

//-----Regular Series

const getSeries = () : Object => {
    let vSeries = chartConfig.chartType === 'hours' ? chartHoursSeries : chartSeries;
    return vSeries;
}

//-----Periodic Series

const getPeriodicSeries = () : Object => {
    if(props.hidePeriodic) return {};

    const vSettings = getStorageObj(props.chartSettingsStorage);
    let vSeries = {}

    if(props.hidePeriodicToggle || vSettings?.showPeriodic) {
        vSeries = chartConfig.chartType === 'hours' ? chartPeriodicHoursSeries : chartPeriodicSeries;
    }

    return vSeries;
}

//-----Archive

const getArchiveSeries = () : Object => {
    let vSeries = { ...(chartConfig.chartType === 'hours' ? chartArchiveHrsSeries : chartArchiveSeries) };
    removeUnusedArchiveSeries(vSeries);
    renameArchiveSeries(vSeries);
    return vSeries;
}
const getBaselineSeries= () : Object => {
    let vSeries = {...chartBaselineSeries};
    removeUnusedBaselineSeries(vSeries);
    return vSeries;
}

const renameArchiveSeries = (pArchiveSeries: Object) =>{
    dsProjectBaselineArchives.getData().forEach(x => {
        const archiveID = x.ArchiveID;

        for (const key in pArchiveSeries) {
            if (archiveID === 1 && key.startsWith("Archive1")) {
                pArchiveSeries[key].name = pArchiveSeries[key].name.replace('Archive1', x.BaselineName + ' - ') ; // Change the name for Archive2
            } else if (archiveID === 2 && key.startsWith("Archive2")) {
                pArchiveSeries[key].name = pArchiveSeries[key].name.replace('Archive2', x.BaselineName + ' - ') ; // Change the name for Archive2
            }
        }
    });
}

const removeUnusedArchiveSeries = (pArchiveSeries: Object) => {
    // Array to store unique ArchiveIDs from dsProjectBaselineArchives.getData()
    const uniqueArchiveIDs = new Set();

    // Iterate over the data from dsProjectBaselineArchives
    dsProjectBaselineArchives.getData().forEach(x => {
        uniqueArchiveIDs.add(x.ArchiveID);
    });

    // Iterate over the keys of pArchiveSeries
    for (const key in pArchiveSeries) {
        // Extract the ArchiveID from the key
        const archiveID = key.match(/\d+/)[0]; // Extracts the numeric part from the key

        // Check if the uniqueArchiveIDs set does not contain the ArchiveID
        if (!uniqueArchiveIDs.has(parseInt(archiveID))) {
            // If it doesn't exist, delete the entry from pArchiveSeries
            delete pArchiveSeries[key];
        }
    }

}
const removeUnusedBaselineSeries = (pBaselineSeries: Object) => {    
    let baselineTotalExists = false;
    let baselineValueExists = false;
    let baselineValuePeriodicExists = false;

    dsWBSCutOffsChart?.getData().forEach(x => {
        if(x.OriginalBaselineTotal > 0){
            baselineTotalExists = true;
        }
        if(x.OriginalBaselineValue > 0){
            baselineValueExists = true;
        }
        if(x.OriginalBaselineValuePeriodic > 0){
            baselineValuePeriodicExists = true;
        }
    });
    
    let vSettings = getStorageObj(props.chartSettingsStorage);

    // Iterate over the keys of pBaselineSeries
    for (const key in pBaselineSeries) {
        
        // If it doesn't exist, delete the entry from pBaselineSeries
        if (!baselineTotalExists && pBaselineSeries[key].uniqueName == 'OriginalBaselineTotal') {
            delete pBaselineSeries[key];
        }else if (!baselineValueExists && pBaselineSeries[key].uniqueName == 'OriginalBaselineValue') {
            delete pBaselineSeries[key];
        }else if (!baselineValuePeriodicExists && pBaselineSeries[key].uniqueName == 'OriginalBaselineValuePeriodic' || props.hidePeriodic || (!props.hidePeriodicToggle && !vSettings?.showPeriodic ) ){
            delete pBaselineSeries[key];
        }
    }

}

//--------------------------------------------------------------------------------------------------//
//-----------------------------------     CHART SETTINGS        ----------------------------------- //
//--------------------------------------------------------------------------------------------------//

const chartConfig = {
    chartType: props.chartType || 'costs', // if empty type was passed
    title:''
}

function getChartSettings(): Object {
    const chartSettings = {
        chart: {
            events: {
                beforePrint() {
                    this.setSize(992, 600, false); // @width, @height, @animation
                },
                afterPrint() {
                    this.setSize(null, null, false);
                }
            },
            zoomType: props.zoomType,
            // scrollablePlotArea: { //only one axis works, not both
                // minHeight: 600,
                // scrollPositionY: 1,
                // minWidth: 300,
                // scrollPositionX: 0,
            // }
        },

        title: {
            text: chartConfig.title || '',
            style: {
                fontSize: '15px',
                fontFamily: 'system-ui'
            }
        },
        accessibility:{
            enabled: false
        },

        yAxis: [
            {
                title: {
                    text: $t('Cumulative')
                },
                min: 0
            },
            {
                title: {
                    text: $t('Periodic')
                },
                opposite: true,
                visible: getPeriodicInitialState(),
                min: 0,
                isPeriodic: true
            }
        ],

        xAxis: {
            accessibility: {
                rangeDescription: '',
            },
            type: "datetime",
            labels: {
                enabled: true,
                step: 5,
                rotation: 90
            }
        },

        plotOptions: {
            series: {
                events: {
                    legendItemClick: function (e) {
                        //this fires before series visibility is toggled
                        onLegendItemClick(e);
                    }
                },
                marker: {
                    enabled: false
                }
            }
        },

        legend: {
            enabled: getLegendInitialState()
        },

        exporting: {
            buttons: {
                contextButton: {
                    menuItems: getMenuItems()
                }
            },
            menuItemDefinitions: {
                legend: legendButtonDefinition(),
                periodic: periodicButtonDefinition(),
                costs: {
                    onclick: function () {
                        chartConfig.chartType = 'costs';
                        createOrRefreshChart();
                    },
                    text: chartConfig.chartType === 'costs' ? '<i class="bi bi-check"></i> Costs' : 'Costs'
                },
                hours: {
                    onclick: function () {
                        chartConfig.chartType = 'hours';
                        createOrRefreshChart();
                    },
                    text: chartConfig.chartType === 'hours' ? '<i class="bi bi-check"></i> Hours' : 'Hours'
                },
            }
        },

        credits: {
            enabled: false
        },

        tooltip: {
            crosshairs: true,
            shared: true,
            valueSuffix: "",
        },

    };
    return chartSettings;
}


//--------------------------------------------------------------------------------------------------//
//-----------------------------------           UTILS           ----------------------------------- //
//--------------------------------------------------------------------------------------------------//

function getStorageObj( pStorageName: string ) : Object {
    return Object(JSON.parse( localStorage.getItem(pStorageName) ) ?? {});
}

function setStorageObj( pStorageName: string, pStorageObj: Object ) {
    if(!pStorageName) return;
    localStorage.setItem( pStorageName, JSON.stringify(pStorageObj) );
}

function scaleXAxis(pDataLength) {
    if (pDataLength < 40) {
        return Math.ceil((pDataLength + 1) / 10);
    } else if (pDataLength < 50) {
        return 6;
    }
    return 12;
}

//--------------------------------------------------------------------------------------------------//

//--------------------------------------------------------------------------------------------------//

defineExpose({
    setChartTitle,
    createOrRefreshChart
}); 

</script>